/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.pointer {
    cursor: pointer;
}

.white {
    background-color: #ffffff;
}

input[type=file]{
    /* width:100px; */
    color:transparent;
}

.extra-info {
  display: none;
  line-height: 30px;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 50px;
}
 
.info:hover .extra-info {
  display: block;
  cursor: hand; 
  cursor: pointer;
  border-style: inset;
  border-color: #213d53;
  border-width: 3px;
}
 
.info {
  font-size: 20px;
  padding-left: 5px;
  width: 20px;
  border-radius: 15px;
}
 
.info:hover {
  background-color: white;
  padding: 0 0 0 5px;
  width: 315px;
  text-align: left !important;
}

.grade-btn:hover{
  background:rgb(112, 233, 227)!important;
}

.pagination-wrapper {
  overflow-x:auto;
}

.pagination {
  justify-content: center;
  margin:30px;
}

.active-image{
  border: 3px dotted green;
}

.active-link {
  background-color: #536DFE;
  color: white;
}

input.largerCheckbox {
  width: 30px;
  height: 30px;
}

/************ BANNER IMAGES ***************/
.banners-list h1 {
  font-size: 2rem;
  padding-top: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}
.banners-list h5 {
  color: purple;
  font-size: 1.7rem;
}
.modal-heading {
  color: purple;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  padding: 1rem;
}
/* .modal-content {
  text-align: center;
} */
.full-width {
  width: 100%;
}
.banners-list .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  height: 130vh;
}
.add-new-btn {
  background: #536dfe linear-gradient(180deg, #6d83fe, #536dfe) !important;
  border-color: #536dfe !important;
}

.add-new-btn:last-child {
  margin-top: 1rem;
}
.rm-img {
  float: right;
  border: none;
  background: #333;
  color: #fff;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
}
.banner-upload .btn {
  display: inline-block;
  width: auto;
  margin-top: 3rem;
  margin-left: 2rem;
  width: 12rem;
}
.banner-img {
  width: 50vh;
  height: 30vh;
  object-fit: cover;
}
.red-error{
  color: red;
}
/*****************************************/

/* Banner Feature Styles
* DO NOT REMOVE THIS
*/

.banners > li {
  cursor: pointer;
}

.banners .active {
  background: #536dfe linear-gradient(180deg, #6d83fe, #536dfe) !important;
  border-color: #536dfe !important;
}

.banners .active:hover {
  color: #fff !important;
}

/* Banner Feature Styles
* DO NOT REMOVE THIS
*/