@import "../../../styles/reduction.scss";

.question{
    display: $flex;
    align-items: $align-center;
    flex-direction: $flex-col;
    &__type{
        display: $flex;
        margin-bottom: $mb-2;
         &__selection{
            display: $flex;
            align-items: center;
            &__label{
                margin: $mx-0;
                font-weight: bold;
            }
            &__btn{
                margin-left: 0.5rem;
            }
        }
        &__selection:first-child{
            margin-right: 1rem;
        }
    }
   
    &__description, &__preview{
        display: $flex;
        flex-direction: $flex-col;
        width: 70%;
        margin-bottom: $mb-2;

        &__input,     &__content{
            padding: 0.5rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;

            span{
                word-break: break-word;
                mjx-math{
                    white-space: initial;
                    line-height: 1;
                    font-size: 1.2rem;
                }
            }
            
        }

      
    } 
    &__add__ans__btn{
        margin-bottom: $mb-2;
    }  
}

.answer{
    display: $flex;
    align-items: $align-center;
    flex-direction: $flex-col;
    margin-left: $ml-2;
    &__option{
        display: $flex;
        width: 100%;
        margin-bottom: $mb-2;

        &__label{
            align-self: $align-center;
            width: 20%;
        }
        &__description{
            display: $flex;
            flex-direction: $flex-col;
            width: 35%;

            &__type{
                display: $flex;
                &__selection{
                    display: $flex;
                    align-items: $align-center;
                    &__label{
                        margin: $mx-0;

                    }
                    &__btn{
                        margin-left: 0.5rem;
                    }
                }
                &__selection:first-child{
                    margin-right: 1rem;

                }
            }

        }
        &__preview{
            display: $flex;
            flex-direction: $flex-col;
            width: 35%;
            margin-left: $ml-2;
            &__label{
                margin: $mx-0;
            }
            &__content{
                padding: 1.2rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
            }
        }
    }
    &__field{
        display: flex;
        width: 100%;
        margin-bottom: $mb-2;
        &__label{
            width: 20%;
        }
        &__select{
            width: 35%;
        }
    }
    &__btn{
        display: flex;
        &__cancel{
            margin-left: $ml-2;
        }
    }

}